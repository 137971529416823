import * as actionTypes from './types';
import {
  slServicesApi,
  fulfillmentApi,
  catalogApi,
} from '../../sqdApis';
import {
  lockerChangeLogPropertyEnum,
  customItemStatusEnum,
  productTypeEnum,
} from '@constants/enums/lockerEnums';
import { lockerMgrS3Logos } from '@constants/common';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import lockerUrls from '@constants/sqdApiUrls/lockerUrls';
import productCatalogUrls from '@constants/sqdApiUrls/productCatalogUrls';
import {
  decorationMethodEnum,
  personalizationColorTypeEnum,
} from '@constants/enums/decorationEnums';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import {
  handleApiError,
  makeApiCallWithErrorModal,
  makeApiCallWithSubmissionError,
  makeApiCall,
} from '@util/apiHelper';
import {
  formatPercentageAmount,
  toCents,
} from '@util/numberHelpers';
import {
  mapFromServicesApiPersonalizationType,
  mapToServicesApiPersonalizationType,
} from '@util/mappingHelper';
import { uploadImage } from '../support/actions';
import { isDefined } from '@util/valueHelpers';
import { putHomefieldApiV2MerchandisingTargetIdToggleoptin } from '@api/accounts/merchandising';

export const updateLockerInfo = (lockerInfo) => ({
  type: actionTypes.UPDATE_LOCKER_INFO,
  payload: lockerInfo,
});

export const updateLockerChangeLogs = (data) => {
  const payload = data?.map((x) => {
    if (x.property === lockerChangeLogPropertyEnum.Markup) {
      x.oldValue = formatPercentageAmount(x.oldValue);
      x.newValue = formatPercentageAmount(x.newValue);
    }

    return x;
  });

  return ({
    type: actionTypes.UPDATE_LOCKER_CHANGE_LOGS,
    payload,
  });
};

export const updateFulfillmentLockerInfo = (lockerInfo) => ({
  type: actionTypes.UPDATE_FULFILLMENT_LOCKER_INFO,
  payload: lockerInfo,
});

export const resetFulfillmentLockerInfo = () => ({
  type: actionTypes.RESET_FULFILLMENT_LOCKER_INFO,
});

export const updateFulfillmentLockersInfo = (lockersInfo) => ({
  type: actionTypes.UPDATE_FULFILLMENT_LOCKERS_INFO,
  payload: lockersInfo,
});

export const updateCSSMetaInfo = (logoIds, colors) => ({
  type: actionTypes.UPDATE_CSS_META_INFO,
  payload: {
    logoIds,
    colors,
  },
});

export const updateColoredStyleSelections = (css) => ({
  type: actionTypes.UPDATE_COLORED_STYLE_SELECTIONS,
  coloredStyleSelections: css,
});

export const updateCachedImageForCSS = (id, imgUrl) => ({
  type: actionTypes.UPDATE_CACHED_IMAGE_FOR_CSS,
  payload: {
    id,
    imgUrl,
  },
});

export const updateLockerManagerTableState = (data) => ({
  type: actionTypes.UPDATE_LOCKER_MANAGER_TABLE_STATE,
  payload: data,
});

export const updateLockerManager3MonthSalesTableState = (data) => ({
  type: actionTypes.UPDATE_LOCKER_MANAGER_3_MONTH_SALES_TABLE_STATE,
  payload: data,
});

export const resetLockerManagerTableState = () => ({
  type: actionTypes.RESET_LOCKER_MANAGER_TABLE_STATE,
});

export const updateCurrentQueue = (data) => ({
  type: actionTypes.UPDATE_CURRENT_QUEUE,
  payload: data,
});

export const clearCurrentQueue = () => ({
  type: actionTypes.CLEAR_CURRENT_QUEUE,
});

export const updateCurrentProductQueue = (
  data,
  pageSize,
  filter,
  sortBy,
  sortOrder,
  logos,
  decorationFilter,
  productType,
  colorFilter
) => ({
  type: actionTypes.UPDATE_CURRENT_PRODUCT_QUEUE,
  payload: {
    totalPages: data.totalPages,
    filter,
    hasPreviousPage: data.hasPreviousPage,
    hasNextPage: data.hasNextPage,
    pageNumber: data.pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    logos,
    decorationFilter,
    productType,
    colorFilter,
    queue: mapProductsQueue(data.items),
    totalCount: data.totalCount,
  },
});

export const updateLockerItemsQueue = (data, pageSize) => ({
  type: actionTypes.UPDATE_LOCKER_ITEMS_QUEUE,
  payload: {
    totalPages: data.totalPages,
    hasPreviousPage: data.hasPreviousPage,
    hasNextPage: data.hasNextPage,
    pageNumber: data.pageNumber,
    pageSize,
    queue: mapProductsQueue(data.items),
    totalCount: data.totalCount,
  },
});

export const updateLockerLogos = (data) => ({
  type: actionTypes.UPDATE_LOCKER_LOGOS,
  payload: data,
});

export const updateLockerRosters = (data) => ({
  type: actionTypes.UPDATE_ALL_LOCKER_ROSTERS,
  data,
});

export const changeLogoApprovedStatus = (data) => ({
  type: actionTypes.CHANGE_LOGO_APPROVED_STATUS,
  payload: data,
});

export const updateManagerLockerLogos = (data) => ({
  type: actionTypes.UPDATE_MANAGER_LOCKER_LOGOS,
  payload: data,
});

export const updateLockerColors = (data) => ({
  type: actionTypes.UPDATE_LOCKER_COLORS,
  payload: data,
});

export const updateLockerStyleColors = (data) => ({
  type: actionTypes.UPDATE_LOCKER_STYLE_COLORS,
  payload: data,
});

export const updateLockerFloodColors = (data) => ({
  type: actionTypes.UPDATE_LOCKER_FLOOD_COLORS,
  payload: data,
});

export const updateManagerLogos = (data) => ({
  type: actionTypes.UPDATE_MANAGER_LOGOS,
  payload: data,
});

export const updateLogoBankLogos = (data) => ({
  type: actionTypes.UPDATE_LOGO_BANK_LOGOS,
  payload: data,
});

export const updateLogoBankHistory = (data) => ({
  type: actionTypes.UPDATE_LOGO_BANK_HISTORY,
  payload: data,
});

export const updateLogoBankArchivedLogos = (data) => ({
  type: actionTypes.UPDATE_LOGO_BANK_ARCHIVED_LOGOS,
  payload: data,
});

export const updateAllLockerProducts = (data) => ({
  type: actionTypes.UPDATE_ALL_LOCKER_PRODUCTS,
  payload: data,
});

export const updateLockerManagers = (data) => ({
  type: actionTypes.UPDATE_LOCKER_MANAGERS,
  payload: data,
});

export const updatePartners = (data) => ({
  type: actionTypes.UPDATE_PARTNERS,
  payload: data,
});

export const updateOrganizations = (data) => ({
  type: actionTypes.UPDATE_ORGANIZATIONS,
  payload: data,
});

export const updateAvailableProducts = (data) => ({
  type: actionTypes.UPDATE_AVAILABLE_PRODUCTS,
  payload: data,
});

export const updateBlankProducts = (data) => ({
  type: actionTypes.UPDATE_BLANK_PRODUCTS,
  payload: data,
});

export const updateSportOptions = (data) => ({
  type: actionTypes.UPDATE_SPORT_OPTIONS,
  payload: data,
});

export const updateAllLockerCollections = (data) => ({
  type: actionTypes.UPDATE_ALL_LOCKER_COLLECTIONS,
  data,
});

export const updateLockerBundledCollections = (data) => ({
  type: actionTypes.UPDATE_LOCKER_BUNDLED_COLLECTIONS,
  data,
});

export const updateLockerItem = (data) => {
  if (data.personalizations && data.personalizations.length > 0) {
    data.personalizations = data.personalizations.map((personalization) => ({
      ...personalization,
      personalizationType: mapFromServicesApiPersonalizationType(personalization.personalizationType),
    }));
  }

  return {
    type: actionTypes.UPDATE_LOCKER_ITEM,
    payload: data,
  };
};

export const updateLockerLogoBankLogos = (data) => {
  const mapped = data.map((value) => {
    let customColors = [];

    if (value.custom_colors && value.custom_colors.length > 0) {
      customColors = value.custom_colors.map((cc) => ({ code: cc }));
    }

    const logoColorSelection = (value.logo_color_selections || []).map((c) => ({
      code: c.code,
      displayName: c.display_name,
      id: c.id,
      hexValue: c.hex_value,
      selectionType: c.selection_type,
    }));

    return {
      id: value.id,
      image: value.image,
      isApproved: value.artwork_approved,
      needsReview: value.needs_review,
      reviewedAt: value.reviewed_at,
      decorationMethods: value.decoration_methods,
      colors: value.logo_colors,
      floodColors: (value.flood_colors || []).map((c) => ({
        id: c.id,
        code: c.code,
        brightness: c.brightness,
        hexValue: c.hex_value,
      })),
      colorSelections: customColors.length > 0
        ? [
          ...logoColorSelection,
          customColors,
        ].flat() : logoColorSelection,
      countUsedOnProd: value.count_used_on_prod,
      countUsedOnCustomItems: value.count_used_on_custom_items,
      countOrderedProd: value.count_ordered_prod,
      countOrderedCustomItems: value.count_ordered_custom_items,
    };
  });

  return ({
    type: actionTypes.UPDATE_LOCKER_LOGO_BANK_LOGOS,
    payload: mapped,
  });
};

const mapProductsQueue = (items) => (
  items.map((item) => ({
    ...item,
    isCustomItem: item.is_custom_item,
    isLockerItem: item.is_locker_item,
    coloredStyleId: item.colored_style_id,
    styleId: item.style_id,
    decorationMethod: item.decoration_method,
    merchandisingEffortId: item.merchandising_effort_id,
    imageFront: item.image_front,
    imageBack: item.image_back,
    imageLeft: item.image_left,
    imageRight: item.image_right,
    customName: item.custom_name,
    requiredItem: item.required_item,
    fundraisingAmount: item.fundraising_amount,
    vendorDesignId: item.vendor_design_id,
    timelineDays: item.timeline_days,
    timelineUuid: item.timeline_uuid,
    threadColor: item.thread_color,
    optionId: item.option_id,
    designId: item.design_id,
    liquidPixelsAutomation: item.liquid_pixels_automation,
    maxPersonalizationNameLength: item.max_personalization_name_length,
    personalizationItemType: item.personalization_item_type,
    vendorFulfilledItem: item.vendor_fulfilled_item,
    vendorId: item.vendor_id,
    vendorCode: item.vendor_code,
    collectionsList: item.collections_list?.map((c) => ({
      id: c.id,
      name: c.name,
      itemQuantity: c.item_quantity,
    })),
    decorations: item.locker_item_decorations?.map((d) => ({
      id: d.id,
      type: d.type,
      decorationLocation: d.decoration_location,
      decorationMethod: d.decoration_method,
      createdAt: d.created_at,
      updatedAt: d.updated_at,
      logoId: d.logo_id,
      logoImage: d.image,
      cachedImage: d.cached_image,
      personalizationType: mapFromServicesApiPersonalizationType(d.personalization_type),
      colorId: d.color_id,
      colorHexValue: d.color_hex_value,
      colorCode: d.color_code,
      outlineColorId: d.outline_color_id,
      outlineColorHexValue: d.outline_color_hex_value,
      outlineColorCode: d.outline_color_code,
      personalizationColorType: (d.outline_color_id !== null && d.outline_color_id !== undefined)
        ? personalizationColorTypeEnum.FillAndOutline
        : personalizationColorTypeEnum.Fill,
      required: (d.required === null || d.required === undefined) ? true : d.required,
      decorationHeight: d.decoration_height,
      decorationWidth: d.decoration_width,
    })),
  }))
);

export const fetchLockerInfo = (lockerId) => async (dispatch) => {
  const res = await slServicesApi.get(lockerUrls.lockerDetails(lockerId), { handleBlockingLoading: false });
  if (res) {
    dispatch(updateLockerInfo({
      id: res.id,
      dateCreated: res.date_created,
      organizationName: res.organization_name,
      organizationId: res.organization_id,
      storeName: res.team_name,
      markup: res.markup,
      firstName: res.first_name,
      lastName: res.last_name,
      email: res.email,
      phoneNumber: res.phone_number,
      lockerManagerOrganizationType: res.locker_manager_organization_type,
      discounts: res.discounts,
      updatedAt: res.updated_at,
      storeLink: `https://teamlocker.squadlocker.com/#/lockers/${res.id}`,
      logoUrl: `${lockerMgrS3Logos}/${res.image}`,
      image: res.image,
      status: res.status,
      teamUID: res.team_uid,
      partnerId: res.partner_id,
      lockerOwnerId: res.locker_owner_id,
      logoId: res.logo_id,
      programName: res.program_name,
      programLink: res.program_link,
      logosApproved: res.logos_approved,
      enableMultipleLogos: res.enable_multiple_logos,
      slug: res.slug,
      isParentLocker: res.is_parent_locker,
      approved: res.approved,
      disableEmails: res.disable_emails,
      searchable: res.searchable,
      cancelled: res.cancelled,
      claimed: res.claimed,
      claimedAt: res.claimed_at,
      emailListId: res.email_list_id,
      sport: res.sport,
      sportId: res.sport_id,
      ages: res.ages,
      genders: res.genders,
      playerCount: res.player_count,
      teamType: res.team_type,
      note: res.note,
      discount: res.discount,
      freeShipping: res.free_shipping,
      type: res.locker_type,
      optInAutomerch: res.opt_in_automerch,
      meetsVerifiedDataRequirements: res.meets_verified_data_requirements,
    }));
  }
};

export const getLockerChangeLogs = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerChangeLogs(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLockerChangeLogs(res));
  }
};

export const fetchFulfillmentLockerInfo = (lockerId) => async (dispatch) => {
  const call = fulfillmentApi.get(lockerUrls.lockerFulfillment.lockerDetails(lockerId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateFulfillmentLockerInfo(res));
  } else {
    dispatch(resetFulfillmentLockerInfo());
  }
};

export const fetchFulfillmentLockersInfo = (lockerId) => async (dispatch) => {
  const call = fulfillmentApi.get(lockerUrls.lockerFulfillment.lockersDetails(lockerId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateFulfillmentLockersInfo(res));
  }
};

export const fetchColoredStyleSelectionsForLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.coloredStyleSelections(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    const logoIds = [];
    const colors = [];

    const temp = res.map((item) => {
      if (!logoIds.includes(item.logo_id)) {
        logoIds.push(item.logo_id);
      }

      if (!colors.includes(item.color)) {
        colors.push(item.color);
      }

      return {
        id: item.id,
        cachedImage: item.cached_image,
        logoId: item.logo_id,
        logoFile: item.logo_file_name,
        color: item.color,
        product: item.product_image + '.png',
        grid: item.image_grid,
        styleName: item.style_name,
        decorationMethod: item.decoration_method,
        decorationLocation: item.decoration_location,
      };
    });

    const colorSorting = (a, b) => {
      const colorA = a.color.toLowerCase();
      const colorB = b.color.toLowerCase();
      if (colorA < colorB) {
        return -1;
      }
      if (colorA > colorB) {
        return 1;
      }

      return 0;
    };

    /*
     * Split up response into decoration methods, and sort each
     * array by color.
     */
    const emb = temp.filter((item) => item.decorationMethod.toLowerCase() === 'emb').sort(colorSorting);
    const hag = temp.filter((item) => item.decorationMethod.toLowerCase() === 'hag').sort(colorSorting);
    const other = temp.filter((item) => ((item.decorationMethod.toLowerCase() !== 'emb') &&
      (item.decorationMethod.toLowerCase() !== 'hag'))).sort(colorSorting);

    const collections = {
      cssArray: [
        ...emb,
        ...hag,
        ...other,
      ],
      logoIds,
      colors,
    };

    dispatch(updateCSSMetaInfo(collections.logoIds, collections.colors));

    return dispatch(updateColoredStyleSelections(collections.cssArray));
  }
};

export const cacheLiquidPixelsImage = (id, lockerId, imgUrl) => async (dispatch) => {
  const body = JSON.stringify({
    id,
    lockerId,
    url: imgUrl,
  });
  const call = slServicesApi.post(lockerUrls.cacheImageInCSSRecord, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res?.success) {
    return dispatch(updateCachedImageForCSS(id, res.url));
  }
};

export const fetchLockerManagerTable = (
  pageNumber = 1,
  pageSize = 10,
  filter = '',
  statuses = '',
  sport = '',
  hasOrders = false,
  sortBy = '',
  sortOrder = '',
  organizationId = '',
  lockerManagerId = '',
  partner = ''
) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerTable(
    pageNumber,
    pageSize,
    filter,
    statuses,
    sport,
    hasOrders,
    sortBy,
    sortOrder,
    organizationId,
    lockerManagerId,
    partner
  ), { handleBlockingLoading: false });

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateCurrentQueue({
      totalPages: res.totalPages,
      hasPreviousPage: res.hasPreviousPage,
      hasNextPage: res.hasNextPage,
      queue: res.items,
      totalCount: res.totalCount,
    }));
  }

  return res;
};

export const fetchLockerManagerTableStateUpdate = (
  pageNumber = 1,
  pageSize = 10,
  filter = '',
  statuses = '',
  sport = '',
  partner = '',
  hasOrders = false,
  sortBy = '',
  sortOrder = '',
  organizationId = '',
  lockerManagerId = ''
) => async (dispatch) => {
  const res = await dispatch(fetchLockerManagerTable(
    pageNumber,
    pageSize,
    filter,
    statuses,
    sport,
    partner,
    hasOrders,
    sortBy,
    sortOrder,
    organizationId,
    lockerManagerId
  ));

  if (res && (res.pageNumber !== null && res.pageNumber !== undefined)) {
    dispatch(updateLockerManagerTableState({
      filter,
      statuses,
      sport,
      partner,
      hasOrders,
      sortOrder,
      pageSize,
      sortByState: sortBy,
      pageNumber: res.pageNumber,
      organizationId,
    }));
  }
};

export const fetchLockerManagerProducts = (
  pageNumber = 1,
  pageSize = 12,
  lockerId = '',
  sortBy = '',
  sortOrder = '',
  colorFilter = [],
  decorationFilter = '',
  filter = '',
  logos = [],
  productType = productTypeEnum.All,
  collectionId = '',
  filterOutNonDecoratableItems = false,
  showOnlyMerchandisedItems = false
) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerProductTable(
    pageNumber,
    pageSize,
    lockerId,
    sortBy,
    sortOrder,
    colorFilter,
    decorationFilter,
    filter,
    logos,
    productType,
    collectionId,
    [],
    showOnlyMerchandisedItems
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  if (data) {
    if (productType === productTypeEnum.Css) {
      data.items = data.items.filter((product) => !product.is_locker_item);
    }

    if (filterOutNonDecoratableItems) {
      data.items = data.items.filter((product) => product.decoration_method !== decorationMethodEnum.NA
        && product.decoration_method !== decorationMethodEnum.Undefined);
    }

    dispatch(updateCurrentProductQueue(
      data,
      pageSize,
      filter,
      sortBy,
      sortOrder,
      logos,
      decorationFilter,
      productType,
      colorFilter
    ));
  }
};

export const fetchLockerItems = (
  pageNumber = 1,
  pageSize = 12,
  lockerId = '',
  sortBy = '',
  sortOrder = '',
  productType = productTypeEnum.All,
  collectionId
) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerProductTable(
    pageNumber,
    pageSize,
    lockerId,
    sortBy,
    sortOrder,
    [],
    '',
    '',
    [],
    productType,
    collectionId
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  if (data) {
    dispatch(updateLockerItemsQueue(data, pageSize));
  }
};

export const fetchLockerColors = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerColors(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateLockerColors(res));
  }
};

export const fetchLockerLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateLockerLogos(res));
  }
};

export const fetchLockerFloodColors = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.floodColors(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    await dispatch(updateLockerFloodColors(res));
  }
};

export const fetchManagerLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerManagerLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    await dispatch(updateManagerLogos(res));
  }
};

export const fetchArchivedLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.archivedLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    await dispatch(updateLogoBankArchivedLogos(res));
  }
};

export const fetchUsedLogos = (lockerId, search, decorationMethod) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.usedLogos(lockerId, search, decorationMethod), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res && Array.isArray(res)) {
    const mapped = res.map((value) => {
      let customColors = [];
      if (value.custom_colors && value.custom_colors.length > 0) {
        customColors = value.custom_colors.map((cc) => ({ code: cc }));
      }

      const logoColorSelections = (value.logo_color_selections || []).map((c) => ({
        code: c.code,
        displayName: c.display_name,
        id: c.id,
        hexValue: c.hex_value,
        selectionType: c.selection_type,
      }));

      return {
        id: value.id,
        image: value.image,
        isApproved: value.artwork_approved,
        needsReview: value.needs_review,
        reviewedAt: value.reviewed_at,
        decorationMethods: value.decoration_methods,
        colors: value.logo_colors,
        floodColors: (value.flood_colors || []).map((c) => ({
          id: c.id,
          code: c.code,
          brightness: c.brightness,
          hexValue: c.hex_value,
        })),
        colorSelections: customColors.length > 0
          ? [
            ...logoColorSelections,
            customColors,
          ].flat()
          : logoColorSelections,
        countUsedOnProd: value.count_used_on_prod,
        countUsedOnCustomItems: value.count_used_on_custom_items,
        countOrderedProd: value.count_ordered_prod,
        countOrderedCustomItems: value.count_ordered_custom_items,
      };
    });
    await dispatch(updateLogoBankLogos(mapped));
  }
};

export const fetchAllLockerProducts = (
  lockerId,
  logos = [],
  colors = [],
  decorations = [],
  filter = '',
  floodColors = [],
  filterOutNonDecoratableItems = false,
  productType = productTypeEnum.All
) => async (dispatch) => {
  let data = [];
  let hasNextPage = true;
  let pageNumber = 1;
  const pageSize = 10;
  const sortBy = 'updated_at';
  const sortOrder = sortDirectionShortEnum.Desc;
  const collectionId = undefined;

  while (hasNextPage) {
    const call = slServicesApi.get(lockerUrls.lockerProductTable(
      pageNumber,
      pageSize,
      lockerId,
      sortBy,
      sortOrder,
      colors,
      decorations,
      filter,
      logos,
      productType,
      collectionId,
      floodColors
    ), { handleBlockingLoading: false });

    const res = await makeApiCall(call);
    if (!res) break;

    data.push(...res.items);

    hasNextPage = res.hasNextPage;
    pageNumber++;
  }

  data = data.filter((product) => !product.is_locker_item
    && (!filterOutNonDecoratableItems
      || (filterOutNonDecoratableItems
        && (product.decoration_method !== decorationMethodEnum.NA
          && product.decoration_method !== decorationMethodEnum.Undefined))
    ));

  dispatch(updateAllLockerProducts(data));
};

export const fetchProductsWithoutLogos = (
  lockerId,
  decorations = [],
  filter = '',
  colors = [],
  filterOutNonDecoratableItems = false
) => async (dispatch) => {
  let data = [];
  let hasNextPage = true;
  let pageNumber = 1;
  const pageSize = 10;
  const sortBy = undefined;
  const sortOrder = undefined;
  const productType = productTypeEnum.Css;
  const collectionId = undefined;

  while (hasNextPage) {
    const call = slServicesApi.get(lockerUrls.lockerProductTable(
      pageNumber,
      pageSize,
      lockerId,
      sortBy,
      sortOrder,
      colors,
      decorations,
      filter,
      [],
      productType,
      collectionId
    ), { handleBlockingLoading: false });

    const res = await makeApiCall(call);
    if (!res) break;

    data.push(...res.items);

    hasNextPage = res.hasNextPage;
    pageNumber++;
  }

  data = data.filter((product) => (!product.logos || product.logos.length === 0)
    && !product.is_locker_item
    && (!filterOutNonDecoratableItems
      || (filterOutNonDecoratableItems
        && (product.decoration_method !== decorationMethodEnum.NA
          && product.decoration_method !== decorationMethodEnum.Undefined))
    ));

  if (data.length) {
    dispatch(updateBlankProducts(data));
  }
};

export const fetchManagerLockerLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerManagerLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateManagerLockerLogos(res));
  }
};

export const fetchLockerManagers = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerManagers(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLockerManagers(res));
  }
};

export const fetchLockerLogoBankLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerLogoBankLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res && Array.isArray(res)) {
    return dispatch(updateLockerLogoBankLogos(res));
  }
};

export const fetchPartners = () => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.partners, { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updatePartners(res));
  }
};

export const fetchOrganizations = () => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.organizations, { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrganizations(res));

    return res;
  }
};

export const fetchSportOptions = () => async (dispatch) => {
  const res = await slServicesApi.get(productCatalogUtilityUrls.sports, { handleBlockingLoading: false });
  if (res) {
    dispatch(updateSportOptions(res));
  }
};

export const associateAdminWithLocker = (lockerId, admins) => async () => {
  const body = JSON.stringify({
    ids: admins.map((a) => a.id),
  });

  const call = slServicesApi.post(lockerUrls.lockerManagers(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const removeManagerFromLocker = (lockerId, lockerManager) => async () => {
  const call = slServicesApi.delete(lockerUrls.managerOnLocker(lockerId, lockerManager.id));
  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const updateLockerOwner = (lockerId, lockerManager) => async (dispatch) => {
  const body = JSON.stringify({
    lockerOwnerId: lockerManager.id,
  });

  const call = slServicesApi.put(lockerUrls.lockerOwner(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const response = await makeApiCallWithErrorModal(call);
  if (response) {
    dispatch(updateLockerInfo({
      lockerOwnerId: lockerManager.id,
      email: lockerManager.email,
    }));
  }
};

export const enableCLCForLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.enableCLCForLocker(lockerId, {
    headers: {
      'Content-Type': 'application/json',
    },
  }));

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    dispatch(updateLockerInfo({ enableMultipleLogos: true }));

    return res;
  }
};

export const toggleFreeShippingForLocker = (lockerId, freeShipping) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.toggleFreeShippingForLocker(lockerId, {
    headers: {
      'Content-Type': 'application/json',
    },
  }));

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    dispatch(updateLockerInfo({ freeShipping }));

    return res;
  }
};

export const toggleAutoMerchOptInForLocker = (lockerId) => async (dispatch) => {
  const res = await putHomefieldApiV2MerchandisingTargetIdToggleoptin(lockerId);
  if (res?.success) {
    dispatch(updateLockerInfo({ optInAutomerch: res.result }));

    return res;
  }
};

export const updateProductAssignmentForLogos = async (lockerId, logos, addCssIds, removeCssIds) => {
  try {
    const call = Promise.all(logos.map((logo) => updateProductAssignment(lockerId, logo.id, addCssIds, removeCssIds)));
    const res = await makeApiCallWithErrorModal(call);

    let countSuccess = 0;
    for (const r of (res ?? [])) {
      if (r?.success) {
        countSuccess++;
      }
    }
    if (!res || countSuccess !== res.length) {
      throw new Error('Some logos were not assigned to items');
    }
  } catch (e) {
    handleApiError(e);
  }
};

export const updateProductAssignment = async (lockerId, logoId, addCssIds, removeCssIds) => {
  const body = JSON.stringify({
    lockerId,
    logoId,
    addCssIds,
    removeCssIds,
  });

  const call = await slServicesApi.post(lockerUrls.editProductAssignment(lockerId), {
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const restoreLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.restoreLocker(lockerId));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
  }

  return res;
};

export const getLockerItem = (id) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerItem(id), { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    dispatch(updateLockerItem(res));
  }
};

export const createCustomItem = async (customItem, lockerId, coloredStyleId) => {
  const body = {
    coloredStyleId,
    status: customItem.customItemIsHidden ? customItemStatusEnum.Hidden : customItemStatusEnum.Available,
    discounts: customItem.discounts ?? undefined,
    customName: customItem.customName ?? undefined,
    price: toCents(customItem.price),
    imageFront: customItem.imageFront ?? undefined,
    imageBack: customItem.imageBack ?? undefined,
    imageLeft: customItem.imageLeft ?? undefined,
    imageRight: customItem.imageRight ?? undefined,
    fundraisingAmount: isDefined(customItem.fundraisingAmount) ? toCents(customItem.fundraisingAmount) : null,
    vendorDesignId: customItem.vendorDesignId ?? undefined,
    maxPersonalizationNameLength: customItem.maxPersonalizationNameLength ?? undefined,
    personalizationItemType: customItem.personalizationItemType ?? undefined,
    requiredItem: customItem.requiredItem,
    timelineDays: customItem.timelineDays ?? undefined,
    timelineUuid: customItem.timelineUuid ?? undefined,
    threadColor: customItem.threadColor ?? undefined,
    designId: customItem.designId ?? undefined,
    optionId: customItem.optionId ?? undefined,
    liquidPixelsAutomation: customItem.liquidPixelsAutomation ?? false,
  };

  if (customItem.decorations) {
    const decorationsMapped = customItem.decorations.map((decoration) => ({
      logoId: decoration.logoId ?? undefined,
      lockerItemId: decoration.lockerItemId ?? undefined,
      logoImage: decoration.logoImage ?? undefined,
      type: decoration.type ?? undefined,
      decorationLocation: decoration.decorationLocation ?? undefined,
      decorationMethod: decoration.decorationMethod ?? undefined,
      personalizationType: decoration.personalizationType
        ? mapToServicesApiPersonalizationType(decoration.personalizationType)
        : undefined,
      artworkColorId: decoration.artworkColorId ?? undefined,
      colorId: decoration.colorId ?? undefined,
      outlineColorId: decoration.outlineColorId ?? undefined,
      decorationHeight: decoration.decorationHeight ? parseFloat(decoration.decorationHeight) : undefined,
      decorationWidth: decoration.decorationWidth ? parseFloat(decoration.decorationWidth) : undefined,
      required: decoration.required ?? undefined,
    }));
    body.decorations = decorationsMapped;
  }

  if (customItem.printFiles) {
    const printFiles = [];
    for (const printFile of customItem.printFiles) {
      if (printFile.file) {
        const imageResponse = await uploadImage(printFile.file);
        printFiles.push({
          size: printFile.size,
          imageUrl: imageResponse.result,
        });
      }
    }

    body.printFiles = printFiles;
  }

  if (customItem.imageFrontAttachment) {
    const imgResponse = await uploadImage(customItem.imageFrontAttachment);
    body.imageFront = imgResponse.result ?? undefined;
  }

  if (customItem.imageBackAttachment) {
    const imgResponse = await uploadImage(customItem.imageBackAttachment);
    body.imageBack = imgResponse.result ?? undefined;
  }

  if (customItem.imageLeftAttachment) {
    const imgResponse = await uploadImage(customItem.imageLeftAttachment);
    body.imageLeft = imgResponse.result ?? undefined;
  }

  if (customItem.imageRightAttachment) {
    const imgResponse = await uploadImage(customItem.imageRightAttachment);
    body.imageRight = imgResponse.result ?? undefined;
  }

  const call = slServicesApi.post(lockerUrls.customItems(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const updateCustomItem = async (customItem, lockerId, coloredStyleId) => {
  const body = {
    coloredStyleId,
    status: customItem.customItemIsHidden ? customItemStatusEnum.Hidden : customItemStatusEnum.Available,
    discounts: customItem.discounts ?? undefined,
    price: customItem.price,
    customName: customItem.customName ?? undefined,
    imageFront: customItem.imageFront ?? undefined,
    imageBack: customItem.imageBack ?? undefined,
    imageLeft: customItem.imageLeft ?? undefined,
    imageRight: customItem.imageRight ?? undefined,
    fundraisingAmount: customItem.fundraisingAmount ?? undefined,
    vendorDesignId: customItem.vendorDesignId ?? undefined,
    maxPersonalizationNameLength: customItem.maxPersonalizationNameLength ?? undefined,
    personalizationItemType: customItem.personalizationItemType ?? undefined,
    requiredItem: customItem.requiredItem ?? undefined,
    timelineDays: customItem.timelineDays ?? undefined,
    timelineUuid: customItem.timelineUuid ?? undefined,
    threadColor: customItem.threadColor ?? undefined,
    optionId: customItem.optionId ?? undefined,
    designId: customItem.designId ?? undefined,
    liquidPixelsAutomation: customItem.liquidPixelsAutomation ?? false,
  };
  if (customItem.decorations) {
    const decorationsMapped = customItem.decorations.map((decoration) => ({
      id: decoration.id ?? undefined,
      updatedAt: decoration.updatedAt ?? undefined,
      createdAt: decoration.createdAt ?? undefined,
      cachedImage: decoration.cachedImage ?? undefined,
      logoId: decoration.logoId ?? undefined,
      lockerItemId: decoration.lockerItemId ?? undefined,
      logoImage: decoration.logoImage ?? undefined,
      type: decoration.type ?? undefined,
      decorationLocation: decoration.decorationLocation ?? undefined,
      decorationMethod: decoration.decorationMethod ?? undefined,
      personalizationType: decoration.personalizationType
        ? mapToServicesApiPersonalizationType(decoration.personalizationType)
        : undefined,
      artworkColorId: decoration.artworkColorId ?? undefined,
      colorId: decoration.colorId ?? undefined,
      outlineColorId: decoration.outlineColorId ?? undefined,
      decorationHeight: decoration.decorationHeight ? parseFloat(decoration.decorationHeight) : undefined,
      decorationWidth: decoration.decorationWidth ? parseFloat(decoration.decorationWidth) : undefined,
      required: decoration.required ?? undefined,
    }));
    body.decorations = decorationsMapped;
  }

  if (customItem.printFiles) {
    const printFiles = [];
    for (const printFile of customItem.printFiles) {
      let imageResponse;
      if (printFile.file) {
        imageResponse = await uploadImage(printFile.file);
      }

      const imageUrl = imageResponse ? imageResponse?.result : '';
      printFiles.push({
        size: printFile.size || undefined,
        imageUrl: printFile.imageUrl || imageUrl,
        id: printFile.id || undefined,
      });
    }

    body.printFiles = printFiles.filter((pf) => pf.imageUrl !== '' || pf.id !== undefined);
  }

  if (customItem.imageFrontAttachment) {
    const imgResponse = await uploadImage(customItem.imageFrontAttachment);
    body.imageFront = imgResponse.result;
  }

  if (customItem.imageBackAttachment) {
    const imgResponse = await uploadImage(customItem.imageBackAttachment);
    body.imageBack = imgResponse.result;
  }

  if (customItem.imageLeftAttachment) {
    const imgResponse = await uploadImage(customItem.imageLeftAttachment);
    body.imageLeft = imgResponse.result;
  }

  if (customItem.imageRightAttachment) {
    const imgResponse = await uploadImage(customItem.imageRightAttachment);
    body.imageRight = imgResponse.result;
  }

  const call = slServicesApi.put(lockerUrls.customItem(customItem.id, lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const fetchLockerColoredStyles = (lockerId, styleIds) => async (dispatch) => {
  const lockerColoredStylesCall = slServicesApi.get(
    lockerUrls.lockerColoredStyles(lockerId),
    { handleBlockingLoading: false }
  );

  const lockerColoredStylesByStyleResponse = await makeApiCall(lockerColoredStylesCall);

  if (!lockerColoredStylesByStyleResponse) {
    return lockerColoredStylesByStyleResponse;
  }

  const coloredStylesByStyleDict = {};
  const promises = styleIds.map(async (styleId) => {
    const call = catalogApi.get(productCatalogUrls.coloredStylesForStyle(styleId), { handleBlockingLoading: false });
    const res = await makeApiCall(call);

    if (res) {
      coloredStylesByStyleDict[styleId] = res;
    }
  });

  await Promise.all(promises);

  const {
    allAvailableColors,
    colorsByStyle,
    selectableColoredStyles,
  } = mapLockerStyleColors(coloredStylesByStyleDict, lockerColoredStylesByStyleResponse.styles);

  if (lockerColoredStylesByStyleResponse) {
    return dispatch(updateLockerStyleColors({
      allAvailableColors,
      colorsByStyle,
      selectableColoredStyles,
    }));
  }
};

export const fetchRostersForLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(lockerUrls.lockerRosters(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLockerRosters(res));
  }
};

export const fetchAllCollectionsForLocker = (lockerId) => async (dispatch) => {
  const res = slServicesApi.get(lockerUrls.lockerCollections(lockerId), { handleBlockingLoading: false });

  const data = await makeApiCall(res);

  if (data) {
    dispatch(updateAllLockerCollections(data));
  }
};

export const fetchBundledCollectionForLockers = (lockerIds) => async (dispatch) => {
  const bundledCollectionForLockerDictionary = {};

  const promises = lockerIds.map(async (lockerId) => {
    const call = slServicesApi.get(lockerUrls.lockerBundledCollection(lockerId), { handleBlockingLoading: false });
    const res = await makeApiCall(call);

    if (!res || res.success === false) {
      return;
    }

    bundledCollectionForLockerDictionary[lockerId] = res;
  });

  await Promise.all(promises);

  dispatch(updateLockerBundledCollections(bundledCollectionForLockerDictionary));
};

const mapLockerStyleColors = (coloredStylesByStyle = {}, lockerColoredStylesByStyleId = {}) => {
  let allAvailableColors = [];
  const colorsByStyle = {};
  const selectableColoredStyles = [];

  for (const [
    styleId,
    coloredStyles,
  ] of Object.entries(coloredStylesByStyle)) {
    const availableColorCodes = [];
    const colorCodesInLocker = [];

    const lockerColoredStyles = lockerColoredStylesByStyleId[styleId];
    if (!lockerColoredStyles) {
      continue;
    }

    // Colors in locker
    for (const cs of lockerColoredStyles) {
      if (colorCodesInLocker.includes(cs.color_group)) {
        continue;
      }
      colorCodesInLocker.push(cs.color_group);
    }

    // Available colors
    for (const cs of coloredStyles) {
      if (availableColorCodes.includes(cs.colorGroup)) {
        continue;
      }

      if (!colorCodesInLocker.includes(cs.colorGroup)) {
        availableColorCodes.push(cs.colorGroup);
        selectableColoredStyles.push(cs);
      }
    }

    colorsByStyle[styleId] = {
      inLocker: colorCodesInLocker,
      available: availableColorCodes,
    };
    allAvailableColors.push(...availableColorCodes);
  }

  // All available colors
  allAvailableColors = [...new Set(allAvailableColors)];

  return {
    allAvailableColors,
    colorsByStyle,
    selectableColoredStyles,
  };
};
