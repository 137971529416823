import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { usePostHomefieldApiIntegrationSsapidatasync } from '@api/orders/integration';
import lockerUrls from '@constants/sqdApiUrls/lockerUrls';
import {
  adminPerspectiveUrl,
  lockerManagerUrl,
  lockerLogoBankUrl,
} from '@constants/clientUrls/clientUrls';
import {
  lockerStatusEnum,
  productTypeEnum,
} from '@constants/enums/lockerEnums';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import * as fundraisingPayoutsActions from '@redux/fundraisingPayouts/actions';
import { materialSwal } from '@util/componentHelper';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ActionButton from '@sharedComponents/Buttons/ActionButton';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import CloneLockerModal from '../LockerManagerModals/CloneLockerModal';
import UploadCustomFlyerModal from '../LockerManagerModals/UploadCustomFlyerModal';
import LockerManagerDetailsTable from './LockerManagerDetailsTable';
import LockerManagerDetailsHeader from './LockerManagerDetailsHeader';
import { RootState } from '@redux/index/reducers';
import * as lockerManagerApiCalls from '@APICalls/lockerManager/actions';
import { useClearCacheImagesByLocker } from '@api/squadlockerServices/logos';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRefreshFlyer } from '@api/squadlockerServices/locker-manager';

interface Params {
  lockerId: number;
}

interface Match {
  params: Params;
}

interface OwnProps {
  match: Match;
  lockerInfo: any;
}

const mapDispatchToProps = {
  cancelLocker: lockerManagerApiCalls.cancelLocker,
  claimLocker: lockerManagerApiCalls.claimLocker,
  markMeetsVerifiedDataRequirements: lockerManagerApiCalls.markMeetsVerifiedDataRequirements,
  closeLocker: lockerManagerApiCalls.closeLocker,
  restoreLocker: lockerManagerActions.restoreLocker,
  fetchLockerInfo: lockerManagerActions.fetchLockerInfo,
  fetchFulfillmentLockerInfo: lockerManagerActions.fetchFulfillmentLockerInfo,
  fetchLockerManagerProducts: lockerManagerActions.fetchLockerManagerProducts,
  fetchSportOptions: lockerManagerActions.fetchSportOptions,
  getLockerChangeLogs: lockerManagerActions.getLockerChangeLogs,
  relaunchLocker: lockerManagerApiCalls.relaunchLocker,
  getOrderItemsStatus: fundraisingPayoutsActions.getOrderItemsStatus,
};

const mapStateToProps = ({ lockerManager }: RootState) => ({
  lockerInfo: lockerManager.lockerInfo,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const LockerManagerDetails = React.memo<Props>(({
  match,
  lockerInfo,
  closeLocker,
  cancelLocker,
  claimLocker,
  markMeetsVerifiedDataRequirements,
  relaunchLocker,
  fetchLockerInfo,
  fetchFulfillmentLockerInfo,
  fetchLockerManagerProducts,
  fetchSportOptions,
  restoreLocker,
  getLockerChangeLogs,
  getOrderItemsStatus,
}) => {
  const { claimStorePartnerIds } = useFlags();

  const { mutateAsync: refreshFlyer } = useRefreshFlyer();

  const [
    cloneModalIsOpen,
    setCloneModalIsOpen,
  ] = useState<boolean>(false);
  const [
    refreshingImagesForLocker,
    setRefreshingImagesForLocker,
  ] = useState<boolean>(false);
  const [
    customFlyerModalIsOpen,
    setCustomFlyerModalIsOpen,
  ] = useState<boolean>(false);
  const [
    restoreLockerConfirmationModalIsOpen,
    setRestoreLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    relaunchLockerConfirmationModalIsOpen,
    setRelaunchLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    closeLockerConfirmationModalIsOpen,
    setCloseLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    cancelLockerConfirmationModalIsOpen,
    setCancelLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const [
    claimLockerConfirmationModalIsOpen,
    setClaimLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const [
    unClaimLockerConfirmationModalIsOpen,
    setUnClaimLockerConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const [
    markMeetsVerifiedDataRequirementsModalIsOpen,
    setMarkMeetsVerifiedDataRequirementsModalIsOpen,
  ] = useState<boolean>(false);

  const [
    unMarkMeetsVerifiedDataRequirementsModalIsOpen,
    setUnMarkMeetsVerifiedDataRequirementsModalIsOpen,
  ] = useState<boolean>(false);

  const lockerId = useMemo(
    () => match.params.lockerId,
    [match.params.lockerId]
  );

  const {
    data: syncResponse,
    mutateAsync: syncLocker,
  } = usePostHomefieldApiIntegrationSsapidatasync();

  const {
    data: cacheResponse,
    mutateAsync: clearCacheImages,
  } = useClearCacheImagesByLocker();

  const openRestoreLockerConfirmationModal = useCallback(() => {
    setRestoreLockerConfirmationModalIsOpen(true);
  }, []);

  const closeRestoreLockerConfirmationModal = useCallback(() => {
    setRestoreLockerConfirmationModalIsOpen(false);
  }, []);

  const restoreLockerAction = useCallback(async () => {
    const res = await restoreLocker(lockerId);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
  }, [
    lockerId,
    restoreLocker,
  ]);

  const relaunchLockerAction = useCallback(async () => {
    const res = await relaunchLocker(lockerId);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      fetchLockerInfo(lockerId);
      fetchFulfillmentLockerInfo(lockerId);
    }
  }, [
    fetchFulfillmentLockerInfo,
    fetchLockerInfo,
    lockerId,
    relaunchLocker,
  ]);

  const handleRestoreLockerModalConfirm = useCallback(() => {
    restoreLockerAction();
    closeRestoreLockerConfirmationModal();
  }, [
    closeRestoreLockerConfirmationModal,
    restoreLockerAction,
  ]);

  const openRelaunchLockerConfirmationModal = useCallback(() => {
    setRelaunchLockerConfirmationModalIsOpen(true);
  }, []);

  const closeRelaunchLockerConfirmationModal = useCallback(() => {
    setRelaunchLockerConfirmationModalIsOpen(false);
  }, []);

  const handleRelaunchLockerModalConfirm = useCallback(() => {
    relaunchLockerAction();
    closeRelaunchLockerConfirmationModal();
  }, [
    closeRelaunchLockerConfirmationModal,
    relaunchLockerAction,
  ]);

  const openCloseLockerConfirmationModal = useCallback(() => {
    setCloseLockerConfirmationModalIsOpen(true);
  }, []);

  const closeCloseLockerConfirmationModal = useCallback(() => {
    setCloseLockerConfirmationModalIsOpen(false);
  }, []);

  const handleCloseLockerModalConfirm = useCallback(() => {
    closeLocker(lockerId);
    closeCloseLockerConfirmationModal();
  }, [
    closeCloseLockerConfirmationModal,
    closeLocker,
    lockerId,
  ]);

  const openCancelLockerConfirmationModal = useCallback(() => {
    setCancelLockerConfirmationModalIsOpen(true);
  }, []);

  const closeCancelLockerConfirmationModal = useCallback(() => {
    setCancelLockerConfirmationModalIsOpen(false);
  }, []);

  const openClaimLockerConfirmationModal = useCallback(() => {
    setClaimLockerConfirmationModalIsOpen(true);
  }, []);

  const closeClaimLockerConfirmationModal = useCallback(() => {
    setClaimLockerConfirmationModalIsOpen(false);
  }, []);

  const openUnClaimLockerConfirmationModal = useCallback(() => {
    setUnClaimLockerConfirmationModalIsOpen(true);
  }, []);

  const closeUnClaimLockerConfirmationModal = useCallback(() => {
    setUnClaimLockerConfirmationModalIsOpen(false);
  }, []);

  const openMarkLockerMeetsVerifiedDataRequirementsModal = useCallback(() => {
    setMarkMeetsVerifiedDataRequirementsModalIsOpen(true);
  }, []);

  const closeMarkMeetsVerifiedDataRequirementsConfirmationModal = useCallback(() => {
    setMarkMeetsVerifiedDataRequirementsModalIsOpen(false);
  }, []);

  const openUnMarkMeetsVerifiedDataRequirementsConfirmationModal = useCallback(() => {
    setUnMarkMeetsVerifiedDataRequirementsModalIsOpen(true);
  }, []);

  const closeUnMarkMeetsVerifiedDataRequirementsConfirmationModal = useCallback(() => {
    setUnMarkMeetsVerifiedDataRequirementsModalIsOpen(false);
  }, []);

  const handleCancelLockerModalConfirm = useCallback(() => {
    cancelLocker(lockerId);
    closeCancelLockerConfirmationModal();
  }, [
    cancelLocker,
    closeCancelLockerConfirmationModal,
    lockerId,
  ]);

  const handleClaimLockerModalConfirm = useCallback(() => {
    claimLocker(lockerId, true);
    closeClaimLockerConfirmationModal();
  }, [
    claimLocker,
    closeClaimLockerConfirmationModal,
    lockerId,
  ]);

  const handleUnClaimLockerModalConfirm = useCallback(() => {
    claimLocker(lockerId, false);
    closeUnClaimLockerConfirmationModal();
  }, [
    claimLocker,
    closeUnClaimLockerConfirmationModal,
    lockerId,
  ]);

  const handleMarkMeetsVerifiedDataRequirementsConfirm = useCallback(() => {
    markMeetsVerifiedDataRequirements(lockerId, true);
    closeMarkMeetsVerifiedDataRequirementsConfirmationModal();
  }, [
    markMeetsVerifiedDataRequirements,
    closeMarkMeetsVerifiedDataRequirementsConfirmationModal,
    lockerId,
  ]);

  const handleUnMarkMeetsVerifiedDataRequirementsConfirm = useCallback(() => {
    markMeetsVerifiedDataRequirements(lockerId, false);
    closeUnMarkMeetsVerifiedDataRequirementsConfirmationModal();
  }, [
    markMeetsVerifiedDataRequirements,
    closeUnMarkMeetsVerifiedDataRequirementsConfirmationModal,
    lockerId,
  ]);

  // Replaces ComponentDidMount
  useEffect(() => {
    fetchSportOptions();
  }, []);

  useEffect(() => {
    if (lockerId) {
      fetchLockerInfo(lockerId);
      fetchFulfillmentLockerInfo(lockerId);
      fetchLockerManagerProducts(1, 10, lockerId?.toString(), '', '', [], '', '', [], productTypeEnum.All);
      getOrderItemsStatus(lockerId);
      getLockerChangeLogs(lockerId);
    }
  }, [
    fetchFulfillmentLockerInfo,
    fetchLockerInfo,
    fetchLockerManagerProducts,
    getLockerChangeLogs,
    getOrderItemsStatus,
    lockerId,
  ]);

  const refreshFlyerAction = useCallback(async () => {
    try {
      await refreshFlyer({ lockerId });
      materialSwal('Success', 'Store flyer is being refreshed. This may take up to several minutes.');
    } catch (e) {
      console.info(e);
      materialSwal('Error', 'Unable to refresh flyer', 'error');
    }
  }, [
    lockerId,
    refreshFlyer,
  ]);

  const makeRefreshLockerImagesCall = useCallback(async () => {
    await clearCacheImages({ lockerId });

    setRefreshingImagesForLocker(false);
    materialSwal('Success', 'Image cached has been cleared.  Images will be updated the first time a user navigates to the team locker page.');
  }, [
    clearCacheImages,
    lockerId,
  ]);

  const refreshProductImages = useCallback(() => {
    setRefreshingImagesForLocker(true);
    makeRefreshLockerImagesCall();
  }, [makeRefreshLockerImagesCall]);

  const syncLockerAction = useCallback(async () => {
    const variables = {
      params: {
        organizationId: undefined,
        salesChannelId: lockerId ? [lockerId] : [],
      },
    };

    const res = await syncLocker(variables);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    } else {
      materialSwal('Error', res.message, 'error');
    }
  }, [
    lockerId,
    syncLocker,
  ]);

  const openCloneLockerModal = useCallback(() => {
    setCloneModalIsOpen(true);
  }, []);

  const closeCloneLockerModal = useCallback(() => {
    setCloneModalIsOpen(false);
  }, []);

  const openCustomFlyerUploadModal = useCallback(() => {
    setCustomFlyerModalIsOpen(true);
  }, []);

  const closeCustomFlyerUploadModal = useCallback(() => {
    setCustomFlyerModalIsOpen(false);
  }, []);

  return (
    <>
      <SimpleConfirmationModal
        isOpen={restoreLockerConfirmationModalIsOpen}
        confirm={handleRestoreLockerModalConfirm}
        closeModal={closeRestoreLockerConfirmationModal}
        title={'Restore Locker'}
        confirmationBody={`Are you sure you want to restore Locker L${lockerId}?`}
      />
      <SimpleConfirmationModal
        isOpen={relaunchLockerConfirmationModalIsOpen}
        confirm={handleRelaunchLockerModalConfirm}
        closeModal={closeRelaunchLockerConfirmationModal}
        title={'Relaunch Locker'}
        confirmationBody={`Are you sure you want to relaunch Locker L${lockerId}?`}
      />
      <SimpleConfirmationModal
        isOpen={closeLockerConfirmationModalIsOpen}
        confirm={handleCloseLockerModalConfirm}
        closeModal={closeCloseLockerConfirmationModal}
        title={'Close Locker'}
        confirmationBody={`Are you sure you want to close Locker L${lockerId}?`}
      />
      <SimpleConfirmationModal
        isOpen={cancelLockerConfirmationModalIsOpen}
        confirm={handleCancelLockerModalConfirm}
        closeModal={closeCancelLockerConfirmationModal}
        title={`${lockerInfo.cancelled ? 'Uncancel' : 'Cancel'} Payout`}
        confirmationBody={`Are you sure you want to ${lockerInfo.cancelled ? 'uncancel' : 'cancel'} Locker L${lockerId}?`}
      />
      <SimpleConfirmationModal
        isOpen={claimLockerConfirmationModalIsOpen}
        confirm={handleClaimLockerModalConfirm}
        closeModal={closeClaimLockerConfirmationModal}
        title={'Mark Locker Claimed'}
        confirmationBody={`Are you sure you want to mark Locker L${lockerId} claimed?`}
      />
      <SimpleConfirmationModal
        isOpen={unClaimLockerConfirmationModalIsOpen}
        confirm={handleUnClaimLockerModalConfirm}
        closeModal={closeUnClaimLockerConfirmationModal}
        title={'Mark Locker Unclaimed'}
        confirmationBody={`Are you sure you want to mark Locker L${lockerId} unclaimed?`}
      />
      <SimpleConfirmationModal
        isOpen={markMeetsVerifiedDataRequirementsModalIsOpen}
        confirm={handleMarkMeetsVerifiedDataRequirementsConfirm}
        closeModal={closeMarkMeetsVerifiedDataRequirementsConfirmationModal}
        title={'Mark Locker Verified'}
        confirmationBody={`Are you sure you want to mark no additional information required for Locker L${lockerId}?`}
      />
      <SimpleConfirmationModal
        isOpen={unMarkMeetsVerifiedDataRequirementsModalIsOpen}
        confirm={handleUnMarkMeetsVerifiedDataRequirementsConfirm}
        closeModal={closeUnMarkMeetsVerifiedDataRequirementsConfirmationModal}
        title={'Mark Locker Unverified'}
        confirmationBody={`Are you sure you want to mark additional information required for Locker L${lockerId}?`}
      />
      <div className='container'>
        <CloneLockerModal
          isOpen={cloneModalIsOpen}
          originalLockerId={lockerId}
          closeModal={closeCloneLockerModal}
        />
        <UploadCustomFlyerModal
          isOpen={customFlyerModalIsOpen}
          closeModal={closeCustomFlyerUploadModal}
          lockerId={lockerId}
        />
        <div className='navigation'>
          <BackLink
            text={'Locker Manager List'}
            url={lockerManagerUrl}
          />
          <div className='flex'>
            <ButtonLink
              type={'secondary'}
              text={'Manage Logos'}
              linkTo={lockerLogoBankUrl(lockerId)}
            />
            <ButtonLink
              type={'secondary'}
              text={'Admin Perspective'}
              linkTo={adminPerspectiveUrl(lockerId)}
              linkClasses={'ml-10'}
              openInNewTab={true}
            />
            <ActionButton
              text={'Locker Actions'}
              classes={'ml-10'}
              actions={[
                {
                  text: 'Clone locker',
                  icon: 'file_copy',
                  action: openCloneLockerModal,
                  isVisible: (
                    lockerInfo.status !== lockerStatusEnum.Closed
                    && lockerInfo.status !== lockerStatusEnum.Deleted
                  ),
                },
                {
                  text: 'Refresh Product images',
                  icon: 'style',
                  action: refreshProductImages,
                  isVisible: !refreshingImagesForLocker,
                },
                {
                  text: 'Refreshing Product images',
                  icon: 'sync',
                  action: refreshProductImages,
                  isVisible: refreshingImagesForLocker,
                },
                {
                  text: 'Generate Item Identifiers',
                  icon: 'sync',
                  action: syncLockerAction,
                },
                {
                  text: 'Refresh flyer',
                  icon: 'refresh',
                  action: refreshFlyerAction,
                  hasBottomBorder: false,
                },
                {
                  text: 'Upload Custom Flyer',
                  icon: 'attachment',
                  action: openCustomFlyerUploadModal,
                  hasBottomBorder: false,
                },
                {
                  text: 'Download flyer',
                  icon: 'get_app',
                  linkTo: lockerUrls.downloadFlyer(lockerId),
                  openInNewTab: true,
                },
                {
                  text: 'Restore locker',
                  icon: 'launch',
                  action: openRestoreLockerConfirmationModal,
                  isVisible: lockerInfo.status === lockerStatusEnum.Deleted,
                },
                {
                  text: 'Relaunch locker',
                  icon: 'launch',
                  action: openRelaunchLockerConfirmationModal,
                  isVisible: lockerInfo.status === lockerStatusEnum.Closed && !lockerInfo.isParentLocker,
                },
                {
                  text: 'Mark No Additional Information Required',
                  icon: 'check',
                  action: openMarkLockerMeetsVerifiedDataRequirementsModal,
                  isVisible: !lockerInfo.meetsVerifiedDataRequirements,
                  hasBottomBorder: false,
                },
                {
                  text: 'Mark Additional Information Required',
                  icon: 'check',
                  action: openUnMarkMeetsVerifiedDataRequirementsConfirmationModal,
                  isVisible: lockerInfo.meetsVerifiedDataRequirements,
                  hasBottomBorder: false,
                },
                {
                  text: 'Claim Locker',
                  icon: 'add_to_queue',
                  action: openClaimLockerConfirmationModal,
                  isVisible: (
                    claimStorePartnerIds &&
                    claimStorePartnerIds.length !== 0 &&
                    claimStorePartnerIds.includes(lockerInfo.partnerId) &&
                    !lockerInfo.claimed),
                  hasBottomBorder: true,
                },
                {
                  text: 'Unclaim locker',
                  icon: 'remove_from_queue',
                  action: openUnClaimLockerConfirmationModal,
                  isVisible: (
                    claimStorePartnerIds &&
                    claimStorePartnerIds.length !== 0 &&
                    claimStorePartnerIds.includes(lockerInfo.partnerId) &&
                    lockerInfo.claimed),
                  hasBottomBorder: true,
                },
                {
                  text: 'Close locker',
                  icon: 'close',
                  action: openCloseLockerConfirmationModal,
                  isVisible: (
                    lockerInfo.status !== lockerStatusEnum.Closed
                    && lockerInfo.status !== lockerStatusEnum.Deleted
                  ),
                  isDangerous: true,
                  hasBottomBorder: false,
                },
                {
                  text: 'Uncancel locker',
                  icon: 'check',
                  action: openCancelLockerConfirmationModal,
                  isVisible: (
                    lockerInfo.status !== lockerStatusEnum.Closed
                    && lockerInfo.status !== lockerStatusEnum.Deleted
                    && lockerInfo.cancelled
                  ),
                },
                {
                  text: 'Cancel locker',
                  icon: 'block',
                  action: openCancelLockerConfirmationModal,
                  isVisible: (
                    lockerInfo.status !== lockerStatusEnum.Closed
                    && lockerInfo.status !== lockerStatusEnum.Deleted
                    && !lockerInfo.cancelled
                  ),
                  isDangerous: true,
                  hasBottomBorder: false,
                },
              ]}
              disabled={false}
            />
          </div>
        </div>
        <LockerManagerDetailsHeader lockerInfo={lockerInfo} />
        <LockerManagerDetailsTable lockerId={lockerId} />
      </div>
    </>
  );
});

export default connector(LockerManagerDetails);
