import { storefrontRoot } from '@constants/urls/configUrls';
import {
  selectionTypeEnum,
  decorationMethodEnum,
} from '@constants/enums/decorationEnums';
import { productTypeEnum } from '@constants/enums/lockerEnums';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { buildQueryString } from '@util/stringHelpers';

export const lockerManager = 'homefield/api/lockermanager' as const;
export const lockers = 'homefield/api/lockers' as const;
export const locker = `${lockerManager}/lockers` as const;
export const lockerItems = `${lockerManager}/locker-items` as const;

export const lockerDetails = (lockerId: number): string => `${locker}/${lockerId}`;
export const products = (lockerId: number): string => `${lockerDetails(lockerId)}/products`;
export const lockerManagers = (lockerId: number): string => `${lockerDetails(lockerId)}/managers`;
export const refreshCachedImages = (lockerId: number): string => `${lockerDetails(lockerId)}/refresh-cached-image`;
export const lockerCollections = (lockerId: number): string => `${lockerDetails(lockerId)}/collections`;
export const lockerBundledCollection = (lockerId: number): string => `${lockerDetails(lockerId)}/bundled-collection`;
export const lockerChangeLogs = (lockerId: number): string => `${lockerDetails(lockerId)}/change-logs`;

export const lockerTable = (
  pageNumber: number,
  pageSize: number,
  filter: string | null = null,
  status: string | null = null,
  sportId: number | null = null,
  hasOrders: boolean | null = null,
  sortColumn: string | null = null,
  sortDirection: string | null = null,
  organizationId: number | null = null,
  lockerManagerId: number | null = null,
  partnerId: number | null = null
): string => `${locker}${buildQueryString({
  pageNumber,
  pageSize,
  status,
  sportId,
  hasOrders,
  filter,
  sortColumn,
  sortDirection,
  organizationId,
  lockerManagerId,
  partnerId,
})}`;

export const lockerProductTable = (
  pageNumber: number,
  pageSize: number,
  lockerId: number,
  sortColumn: string,
  sortDirection: string,
  color: string[],
  decorationMethods: (keyof typeof decorationMethodEnum)[],
  search: string,
  logos: number[],
  productType: keyof typeof productTypeEnum,
  collectionId: number,
  floodColor: string[],
  showOnlyMerchandisedItems?: boolean
): string => `${products(lockerId)}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn: !sortColumn ? 'updated_at' : sortColumn,
  sortDirection: !sortDirection ? sortDirectionShortEnum.Desc : sortDirection,
  color,
  decorationMethods,
  logos,
  search,
  productType,
  collectionId,
  floodColor,
  merchandising: showOnlyMerchandisedItems,
})}`;

export const lockerSuggestions = (
  filter: string,
  limit: number,
  hideParentLockers: boolean
): string => `${lockers}/suggestions${buildQueryString({
  filter: encodeURIComponent(filter),
  limit,
  hideParentLockers,
})}`;

export const lockerOwner = (lockerId: number): string => `${lockerDetails(lockerId)}/owner`;
export const enableCLCForLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/enable-clc`;
export const toggleFreeShippingForLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/toggle-free-shipping`;

export const closeLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/close`;
export const cancelLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/cancel`;
export const claimLocker = (lockerId: number, claimed: boolean): string => `${lockerDetails(lockerId)}/claim${buildQueryString({ claimed })}`;
export const markMeetsVerifiedDataRequirements = (lockerId: number, meetsVerifiedDataRequirements: boolean): string => `${lockerDetails(lockerId)}/mark-meets-verified-data-requirements${buildQueryString({ meetsVerifiedDataRequirements })}`;
export const restoreLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/restore`;
export const relaunchLocker = (lockerId: number): string => `${lockerDetails(lockerId)}/relaunch`;
export const cloneLocker = (
  lockerId: number,
  teamName: string,
  teamType: string,
  sportId: number,
  ignoreLogos: boolean,
  ignoreCollections: boolean,
  partnerId: number | null,
  partnerTeamUid: string,
  lockerManagerId: number | null
): string => `${locker}/${lockerId}/clone${buildQueryString({
  teamName,
  teamType,
  sportId,
  ignoreLogos,
  ignoreCollections,
  partnerId,
  partnerTeamUid,
  lockerManagerId,
})}`;
export const addProducts = (lockerId: number): string => `${products(lockerId)}/add`;
export const removeProducts = (lockerId: number): string => `${products(lockerId)}.remove`;

export const floodColors = (lockerId: number): string => `${locker}/${lockerId}/flood-colors`;
export const lockerColoredStyles = (lockerId: number): string => `${locker}/${lockerId}/colored-styles`;
export const lockerColoredStyleSelections = (lockerId: number): string => `${locker}/${lockerId}/colored-style-selections`;
export const lockerColors = (lockerId: number): string => `${locker}/${lockerId}/colors`;

export const sports = `${lockerManager}/sports` as const;
export const organizations = `${lockerManager}/organizations` as const;
export const partners = `${lockerManager}/partners` as const;

export const managerOnLocker = (lockerId: number, managerId: number): string => `${lockerManagers(lockerId)}/${managerId}`;
export const editProductAssignment = (lockerId: number): string => `${products(lockerId)}/editAssignment`;

export const lockerRosters = (lockerId: number): string => `${lockerDetails(lockerId)}/rosters`;

export const customItems = (lockerId: number): string => `${lockerDetails(lockerId)}/custom-items`;
export const customItem = (id: number, lockerId: number): string => `${customItems(lockerId)}/${id}`;
export const items = (lockerId: number, importLogos: boolean = false): string => `${lockerDetails(lockerId)}/items${buildQueryString({ importLogos })}`;
export const lockerItem = (id: number): string => `${lockerItems}/${id}`;

/** Logos */
export const logos = `${lockerManager}/logos`;
export const logoAction = (logoId: number, action: string): string => `${logos}.${action}/${logoId}`;

export const requeueLogoForReview = (logoId: number): string => logoAction(logoId, 'requeue-for-review');

export const logo = (
  logoId: number,
  selectionType: keyof typeof selectionTypeEnum
): string => `${logos}/${logoId}${buildQueryString({ selectionType })}`;

export const archiveLogo = (logoId: number): string => `${logos}/${logoId}`;
export const restoreLogo = (logoId: number): string => logoAction(logoId, 'restore');

export const lockerManagerLogos = (lockerId: number): string => `${lockerManager}/managers/${lockerId}/logos`;
export const archivedLogos = (lockerId: number): string => `${lockerManagerLogos(lockerId)}?deleted=true`;
export const usedLogos = (
  lockerId: number,
  search: string,
  decorationMethod: keyof typeof decorationMethodEnum
): string => `${locker}/${lockerId}/used-logos${buildQueryString({
  search,
  decorationMethod,
})}`;
export const replaceLogo = (lockerId: number): string => `${products(lockerId)}/replaceLogo`;

export const lockerLogoBankLogos = (lockerId: number): string => `${lockerDetails(lockerId)}/locker-logos`;
export const logoFromLockerLogos = (lockerId: number, logoId: number): string => `${lockerLogoBankLogos(lockerId)}/${logoId}`;
export const lockerLogos = (lockerId: number): string => `${locker}/${lockerId}/logos`;

export const cacheImageInCSSRecord = 'colored_style_selections/cache';
export const coloredStyleSelections = (lockerId: number): string => `colored-style-selections/locker/${lockerId}`;

/** Flyers */
export const lockerManagerStoreFlyer = (lockerId: number): string => `${lockerDetails(lockerId)}/store-flyer`;
export const downloadFlyer = (lockerId: number): string => `${storefrontRoot}/lockers/${lockerId}/download_flyer`;

export const lockerFulfillment = {
  lockerDetails: (lockerId: number): string => `${lockers}/${lockerId}`,
  lockersDetails: (lockerId: number): string => `${lockers}${buildQueryString({ lockerId }, true)}`,
  lockerArtworks: (lockerId: number): string => `${lockers}/${lockerId}/logos`,
};

export default {
  lockerManager,
  lockerManagers,
  locker,
  lockerDetails,
  products,
  lockerTable,
  closeLocker,
  cancelLocker,
  claimLocker,
  markMeetsVerifiedDataRequirements,
  lockerSuggestions,
  sports,
  lockerOwner,
  managerOnLocker,
  editProductAssignment,
  restoreLocker,
  relaunchLocker,
  floodColors,
  customItems,
  customItem,
  items,
  logo,
  archiveLogo,
  usedLogos,
  lockerManagerLogos,
  archivedLogos,
  refreshCachedImages,
  cloneLocker,
  lockerProductTable,
  organizations,
  partners,
  lockerLogoBankLogos,
  logoFromLockerLogos,
  restoreLogo,
  lockerLogos,
  lockerColors,
  lockerColoredStyles,
  lockerColoredStyleSelections,
  cacheImageInCSSRecord,
  coloredStyleSelections,
  replaceLogo,
  enableCLCForLocker,
  removeProducts,
  lockerManagerStoreFlyer,
  addProducts,
  requeueLogoForReview,
  toggleFreeShippingForLocker,
  downloadFlyer,
  lockerItem,
  lockerCollections,
  lockerChangeLogs,
  lockerFulfillment,
  lockerRosters,
  lockerBundledCollection,
};
